/* General Styles */

.name-container {
  display: flex;
  justify-content: space-between;
}

.view-form .icon-close {
  bottom: 7px !important;
}

.form-name {
  font-size: 22px !important;
}

.view-form > .footer-card {
  height: 61px;
  width: 100% !important;
  border-radius: 2px;
  padding: 0px 0px !important;
  margin: 0px 0px !important;
  align-items: center;
  box-shadow: 0px -3px 3px #0000000d;
  display: flex;
  justify-content: space-around;
  bottom: 0;
}

.view-form > .footer-card {
  position: static !important;
}

/* Mobile Styles */
@media (max-width: 767px) {
  .view-form {
    padding: 10px;
  }

  .view-form {
    margin: 0 auto;
    max-width: 1200px;
    padding: 20px;
  }

  .form-name {
    font-size: 20px;
  }

  .icon {
    font-size: 16px;
  }

  .custom-button {
    margin-right: 10px;
  }

  .col-md-12{
    padding: none !important;
  }
}

/* Tablet Styles */
@media (min-width: 768px) and (max-width: 991px) {
  .view-form {
    padding: 15px;
  }

  .form-name {
    font-size: 22px;
  }

  .icon {
    font-size: 20px;
  }
}

/* Desktop Styles */
@media (min-width: 992px) {
  .view-form {
    padding: 20px;
  }

  .form-name {
    font-size: 24px;
  }

  /* .icon {
    font-size: 24px;
  } */
}

.err-text-help{
  color: red !important;
  font-size:10px;
  margin-top: -10px;
  margin-bottom: 10px;
}
